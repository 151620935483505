import { Home } from "./pages/Home";
import { GlobalStyle } from "./styles/global";

function App() {
  return (
    <>
      <Home />
      <GlobalStyle/>
    </>
  );
}

export default App
