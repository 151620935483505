import { createGlobalStyle } from "styled-components";

export const GlobalStyle = createGlobalStyle`
  * {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
  }
  body {
    background: url("https://grupoclickmix.com.br/bg.jpg");
  }
  main {
    max-width: 965px;
    margin: 0 auto;
  }
  section {
    height: 510px
  }
  @media (max-width: 768px) {
    section {
      height: auto;
    }
    section + section {
      margin-top: 2rem;
    }
  }
`;