import revendedor from "../../img/revendedor.png";
import duvidas from "../../img/duvidas.png";
import historia from "../../img/historia.png";

export function Home() {
  return (
    <main>
      <section>
        <a href="revendedor/">
          <img src={revendedor} alt="Quero ser revendedor" width="100%" height="auto"/>
        </a>
      </section>
      <section>
        <a href="faq/">
          <img src={duvidas} alt="Dúvidas frequentes - FAQ" width="100%" height="auto"/>
        </a>
      </section>
      <section>
        <a href="historia/">
          <img src={historia} alt="Nossa historia" width="100%" height="auto"/>
        </a>
      </section>
    </main>
  );
}